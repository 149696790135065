import { CompanyApi } from "@/api"
import Icon from "@/components/base/Icon"
import CompanyStore from "@/store/company"
import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import FooterLogo from "../footer-logo"

const FeedbackButton = ({ disableBtn = false, onClick, testId = "", className = "good", iconSecondary, text }) => {
    return (
        <Paper
            elevation={2}
            className={`btn-review ${className}`}
            onClick={onClick}
            data-testid={testId}
            style={{
                pointerEvents: disableBtn ? "none" : "auto",
                opacity: disableBtn ? 0.5 : 1,
                cursor: disableBtn ? "not-allowed" : "pointer",
            }}
        >
            {disableBtn && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
            {!disableBtn && <Icon name="feedbackCheck" />}
            <Icon name={iconSecondary} />
            <Typography className={`text-review ${className}`}>{text}</Typography>
        </Paper>
    )
}

const FirstStep = ({ redirectToGoogle, openNextStep }) => {
    const { t } = useTranslation()
    const [disableBtn, setDisableBtn] = useState(false)
    const { token, locationId } = CompanyStore()
    const sendAnalytics = useCallback(
        async (type: string) => {
            setDisableBtn(true)
            await CompanyApi.saveClicks({
                locationId,
                token,
                like: type === "like",
            })
            setDisableBtn(false)

            if (type === "like") {
                return redirectToGoogle()
            } else {
                return openNextStep()
            }
        },
        [redirectToGoogle, openNextStep]
    )
    return (
        <Box className="first-step">
            <Typography variant="h1" data-testid="step-one-title">
                {t("FEEDBACK.TITLE")}
            </Typography>
            <Stack flexDirection={"column"} gap={2}>
                <Typography variant="h3">{t("FEEDBACK.STEP_ONE.TITLE")}</Typography>
                <Stack flexDirection="row" gap={2}>
                    <FeedbackButton
                        disableBtn={disableBtn}
                        onClick={() => sendAnalytics("like")}
                        testId="step-one-good"
                        iconSecondary="successHand"
                        text={t("FEEDBACK.STEP_ONE.SATISFIED")}
                    />

                    <FeedbackButton
                        disableBtn={disableBtn}
                        onClick={() => sendAnalytics("dislike")}
                        testId="step-one-bad"
                        className="bad"
                        iconSecondary="warningHand"
                        text={t("FEEDBACK.STEP_ONE.NOT_SATISFIED")}
                    />
                </Stack>
            </Stack>
            <FooterLogo />
        </Box>
    )
}
export default FirstStep
