import env from "@/config/env"
import Url from "@/config/url"
import { ApiService, ShortlinkService } from ".."

class CompanyApi {
    fetchCompanyInfos: any = async ({
        company_id = "",
        location_id = "",
    }: {
        company_id: string
        location_id: string
    }) => {
        return await ApiService.get(`${Url.GET_COMPANY_INFOS}/${company_id}/${location_id}`)
    }

    sendReview: any = async (body) => {
        return await ApiService.post(Url.POST_REVIEWS, body, {
            baseURL: env.API_DISPATCHR_URL,
        })
    }

    saveClicks: any = async (body) => {
        return await ShortlinkService.put(Url.SAVE_CLICKS, body)
    }
}

export default new CompanyApi()
