import { InfType } from "@/types/inf"
import Cookies from "js-cookie"
import { create } from "zustand"

export interface ICompanytore {
    companyName: string
    companyLogo: string
    companyId: string
    isFetching: boolean
    companyFetched: boolean
    locationId: string
    reviewUrl: string
    accessDenied: boolean
    dislike: boolean
    token: string
}

export interface ICompanySetter {
    updateState?: (update: Partial<ICompanytore>) => void
}

const infos = Cookies.get("feedback_metadata") ? (JSON.parse(Cookies.get("feedback_metadata") ?? "") as InfType) : null
const token = Cookies.get("feedback_token") ? Cookies.get("feedback_token") : null

const initalState = {
    companyName: infos?.company_name,
    companyLogo: infos?.company_logo,
    locationId: infos?.location_id,
    reviewUrl: infos?.review_url,
    companyId: infos?.id,
    isFetching: true,
    companyFetched: false,
    accessDenied: false,
    dislike: false,
    token: null,
}

const CompanyStore = create<ICompanytore & ICompanySetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
}))

export default CompanyStore
